@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-cream font-poppins;
}

.text-shadow-lg {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}